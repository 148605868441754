<template>
  <section>
     <serieLista/>
  </section>
</template>

<script>
import { defineComponent } from "vue";
import serieLista from "@/components/serie/serieAll.vue"

export default defineComponent({
  components:{
    serieLista
  },
  data() {
    return {
    };
  },
});
</script>
